<template>
  <div :class="`butt ${getCurrentLangs.lang}`" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('butt.head')"></div>

        <router-link to="/cases/" class="top__back-btn cursor-hover">
          <div class="top__arrow">

            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071" stroke="black" stroke-linecap="round"/>
              <path d="M15.2988 29.973L38.2398 29.9732" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>

          {{ $t('back-btn') }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper" v-for="(key, value) in $t('butt.title')" :key="value">
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class='banner'>
      <picture>
        <source srcset="@/assets/img/cases/butt/banner-mob.jpg" media="(max-width: 425px)">
        <source srcset="@/assets/img/cases/butt/banner-tablet.jpg" media="(max-width: 768px)">
        <img v-parallax.modifier.absY="0.1" src="@/assets/img/cases/butt/banner.jpg" alt="">
      </picture>
    </section>

    <section class="challenge">
      <div class="challenge__double-block">
        <div class="challenge__left">
          <p class="challenge__left-text left-text">{{ $t('butt.challenge.left-text') }}</p>
        </div>
        <div class="challenge__right">
          <p class="challenge__right-text right-text" v-html="$t('butt.challenge.right-text-top')"></p>
          <ul class="challenge__list">
            <li class="challenge__item">
              <span>1.</span>
              <p class="challenge__right-text right-text" v-html="$t('butt.challenge.list[0]')"></p>
            </li>
            <li class="challenge__item">
              <span>2.</span>
              <p class="challenge__right-text right-text" v-html="$t('butt.challenge.list[1]')"></p>
            </li>
            <li class="challenge__item">
              <span>3.</span>
              <p class="challenge__right-text right-text" v-html="$t('butt.challenge.list[2]')"></p>
            </li>
          </ul>
          <p class="challenge__right-text right-text">{{ $t('butt.challenge.right-text-bottom') }}</p>
        </div>
      </div>
    </section>

    <div class="parallax parallax-race">
      <picture>
        <source media="(max-width:600px)" srcset="../../assets/img/cases/butt/chalange-banner-mob.jpg">
        <source media="(max-width:768px)" srcset="../../assets/img/cases/butt/chalange-banner-tablet.jpg">
        <img v-parallax.modifier.absY="0.1" src="../../assets/img/cases/butt/chalange-banner.jpg">
      </picture>
    </div>

    <section class="idea">
      <div class="idea__double-block">
        <div class="idea__left">
          <p class="idea__left-text left-text">{{ $t('butt.idea.left-text') }}</p>
        </div>
        <div class="idea__right">
          <p class="idea__right-text right-text" v-html="$t('butt.idea.right-text')"></p>
          <p class="idea__right-title right-text-bold">{{ $t('butt.idea.right-text-bold') }}</p>
        </div>
      </div>
    </section>

    <section class="solution">
      <div class="solution__double-block">
        <div class="solution__left">
          <p class="solution__left-text left-text">{{ $t('butt.solution.left-text') }}</p>
        </div>
        <div class="solution__right">
          <p class="solution__right-text right-text" v-html="$t('butt.solution.right-text')"></p>
          <p class="solution__right-title right-text-bold">{{ $t('butt.solution.right-text-bold') }}</p>
        </div>
      </div>
    </section>

    <section class="video">
      <div class="video__video-wrapper cursor-hover">
        <div class="video__play-btn cursor-hover" @click="playVideo" v-bind:class="[{ active: !videoTrigger}]"></div>
        <video  v-if="getCurrentLangs.lang === 'en'" @click="playVideo" ref="video" poster="../../assets/img/cases/butt/butt-poster.png">
          <source src="@/assets/video/butt.mp4" type="video/mp4">
        </video>
        <video  v-else @click="playVideo" ref="video" poster="../../assets/img/cases/butt/butt-poster.png">
          <source src="@/assets/video/butt_ukr_subs.mp4" type="video/mp4">
        </video>
      </div>
    </section>

    <section class="results">
      <div class="results__double-block">
        <div class="results__left">
          <p class="solution__left-text left-text">{{ $t('butt.results.left-text') }}</p>
        </div>
        <div class="results__right">
          <p class="results__right-text-bold right-text-bold" v-html="$t('butt.results.right-text-top')"></p>
          <ul class="results__list">
            <li class="results__item">
              <span class="num">2600+</span>
              <p class="results__right-text right-text" v-html="$t('butt.results.vr-experience')"></p>
            </li>
            <li class="results__item">
              <div class="wrapper">
                <span class="num">+31<span class="mark">%</span></span>
                <p class="results__right-text right-text custom" v-html="$t('butt.results.rate')"></p>
              </div>
              <div class="wrapper">
                <span class="num">+5<span class="mark">%</span></span>
                <p class="results__right-text right-text" v-html="$t('butt.results.growth')"></p>
              </div>
            </li>
            <li class="results__item">
              <span class="num">20000+</span>
              <p class="results__right-text right-text " v-html="$t('butt.results.patients')"></p>
            </li>
          </ul>
          <p class="results__right-text-bold bottom right-text-bold" v-html="$t('butt.results.right-text-bottom')"></p>
        </div>
      </div>
    </section>

    <div class="parallax parallax-race">
      <img v-parallax.modifier.absY="0.1" src="../../assets/img/cases/butt/results-banner.png">
    </div>

    <div class="sources">
      1. PharmXplorer data, Rx test, M00-M99 diagnosis, M01A, 2018-2020, prescriptions by all specialties 2. Cupitt, J & Kasipandian, V. (2004). Pain and intramuscular injections. Anaesthesia. 59. 93. 10.1111/j.1365-2044.2004.03589.x. 3. McMurtry CM, Pillai Riddell R, Taddio A, Racine N, Asmundson GJ, Noel M, Chambers CT, Shah V; HELPinKids&Adults Team. Far From "Just a Poke": Common Painful
      Needle Procedures and the Development of Needle Fear. Clin J Pain. 2015 Oct;31(10 Suppl):S3-11. 4. INCH360° VR platform analytics data, period 10/2019-02/2020 content views on F2F calls with view rate more than 90% 5. PharmXplorer data, period 10/2018-02/2019 to 10/2019-02/2020 brand recalls (Doctors; Neurology): Promotions via REPs calls 6. PharmXplorer data, period 10/2018-02/2019 to
      10/2019-02/2020 diclofenac sodium+local anesthetic sales in UAH 7. PharmXplorer data, period 10/2018-02/2019 to 10/2019-02/2020 diclofenac sodium+local anesthetic sales in packs difference
    </div>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/NewFooter.vue';
import similar from '@/components/similar.vue';
import anime from 'animejs';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'butt',
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      similarData: {
        title: 'similar-title-type1',
        prevLink: "/cases/vitrolla",
        nextLink: "/cases/shift-work",
        linkShow: true,
        slider: [
          'slider-shift-work-disorder',
          'slider-vacciland',
          'slider-happy-liver',
          'slider-no-spa',
        ],
      },

    };
  },

  mounted() {


    setTimeout(() => {


      this.setCurrentPage('cases');
      const whySection = document.querySelector('.top');
      const textWrapperAll = whySection.querySelectorAll('.text-wrapper .letters');
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes('®')) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>").replace("®", "<sup>®</sup>");

        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({loop: false})
          .add({
            targets: '.text-wrapper .letter',
            translateY: ['1.1em', 0],
            translateZ: 0,
            duration: 750,
            delay: (el, i) => 50 * i,
          });
      });
    }, 20)
  },
  methods: {
    ...mapMutations([
      'setCurrentPage',
    ]),

    playVideo() {
      const mediaVideo = this.$refs.video;
      if (mediaVideo.paused) {
        mediaVideo.play();
        this.videoTrigger = true;
        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
      }
    },
  },

  computed: {
    ...mapGetters(['getCurrentLangs'])
  }
}
</script>

<style lang="scss" scoped>
.butt {


  &.ua {
    .results {

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 42px;

        @media screen and (max-width: 425px) {
          margin-bottom: 0 !important;
        }

        &:nth-child(2) {
          justify-content: space-between;
          @media screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .wrapper {
            width: 90%;


            @media screen and (max-width: 1024px) {
              justify-content: flex-start;
              align-items: center;
            }
            @media screen and (max-width: 425px) {
              align-items: flex-start;
            }

            &:first-child {
              max-width: 50%;
              margin-right: 15px;

              @media screen and (max-width: 1024px) {
                margin-bottom: 42px;
                max-width: 100%;
              }

              @media screen and (max-width: 425px) {
                margin-bottom: 0 !important;
              }
            }

            &:last-child {
              max-width: 40%;
              @media screen and (max-width: 1024px) {
                max-width: 100%;
              }
            }
          }
        }

        @media screen and (max-width: 425px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &:nth-of-type(2) {
          @media screen and (max-width: 425px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }


      }
    }
  }

  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  .top {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 52px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }

    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all .2s ease;

      @media screen and (min-width: 1023px) {

        &:hover {
          svg {
            path {
              stroke: #29F6D9;
            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29F6D9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all .2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;


      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 80px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;

        &:first-child {
          padding-left: 0;
        }
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 1em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .challenge {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 57px 45px;


    @media screen and (max-width: 768px) {
      padding: 56px 45px 57px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 36px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 650px) {

        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      min-width: 157px;

      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;
      line-height: 1.8em;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__list {
      margin-top: 8px;
    }

    &__item {
      display: flex;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: var(--text-primary-color-case);
        font-weight: 500;
        font-size: 25px;
        line-height: 0.7em;
        margin-right: 10px;
        min-width: 20px;
      }

      &:last-of-type {
        margin-bottom: 11px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 6px;
      }
    }

    &__right {
      margin-left: 15px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      @media screen and (max-width: 650px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }

    }
  }

  .parallax-race {
    margin: 0 auto;
    overflow: hidden;
    max-width: calc(1108px + 90px);
    padding: 0 45px;

    @media screen and (max-width: 768px) {
      padding: 56px 45px 0 45px;
      // margin-top: 64px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 0 0 0;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .idea {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 160px 45px 80px 45px;


    @media screen and (max-width: 768px) {
      padding: 48px 45px 44px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 36px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 650px) {

        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      min-width: 157px;

      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;
      line-height: 1.8em;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 15px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      @media screen and (max-width: 650px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 6px;

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .solution {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 80px 45px 56px 45px;


    @media screen and (max-width: 768px) {
      padding: 0px 45px 0px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 0px 20px 0px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 650px) {

        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      min-width: 157px;

      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;
      line-height: 1.6em;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 15px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      @media screen and (max-width: 650px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 6px;

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .video {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 0px 45px 160px 45px;

    @media screen and (max-width: 768px) {
      padding: 38px 45px;
    }

    @media screen and (max-width: 650px) {
      padding: 40px 20px 48px 20px;
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: 'Play';
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29F6D9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all .2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .results {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 0 45px 60px 45px;


    @media screen and (max-width: 768px) {
      padding: 12px 37px 60px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 0px 20px 36px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      min-width: 157px;

      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &__left-text {
      font-size: 16px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;
      line-height: 1.8em;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 15px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      @media screen and (max-width: 650px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: var(--text-primary-color-case);
      padding-left: 12px;

      @media screen and (max-width: 530px) {
        padding-left: 0;
        margin-bottom: 25px;
      }
    }

    &__right-text-bold {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      text-align: left;
      margin-bottom: 67px;

      @media screen and (max-width: 768px) {
        margin-bottom: 31px;
      }

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 25px;
      }

      &.bottom {
        margin-bottom: 0;
        margin-top: 62px;

        @media screen and (max-width: 768px) {
          max-width: 90%;
        }
        @media screen and (max-width: 425px) {
          margin-top: 7px;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 42px;

      &:nth-child(2) {
        justify-content: space-between;

        .wrapper {
          width: 100%;

          &:first-child {
            margin-right: 15px;

            @media screen and (max-width: 1024px) {
            }
          }

          &:last-child {
            @media screen and (max-width: 1024px) {
            }
          }
        }
      }

      @media screen and (max-width: 530px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
      }

      &:nth-of-type(2) {
        @media screen and (max-width: 530px) {
          flex-direction: row;
          align-items: flex-start;
        }
      }

      .wrapper {
        display: flex;
        align-items: center;

        @media screen and (max-width: 530px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &:first-of-type {
          //margin-right: 12%;

          @media screen and (max-width: 960px) {
            //margin-right: 0px;
          }
          @media screen and (max-width: 530px) {
            //margin-right: 10%;
          }
        }
      }
    }

    .num {
      font-weight: 400;
      font-size: 64px;
      line-height: 88px;
      color: var(--text-primary-color-case);

      @media screen and (max-width: 768px) {
        font-size: 60px;
      }

      @media screen and (max-width: 425px) {
        font-size: 47px;
        line-height: 50px;
      }

      & > .mark {
        font-size: 50px;
        font-weight: 600;

        @media screen and (max-width: 425px) {
          font-size: 36px;
        }
      }
    }
  }

  .sources {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 48px 22% 0px 45px;

    text-align: left;

    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #92A1AE;

    @media screen and (max-width: 768px) {
      padding: 0px 45px 0px 37%;
    }

    @media screen and (max-width: 650px) {
      padding-left: 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 0px 20px 0px 20px;
    }
  }
}
</style>
